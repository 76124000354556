<template>
  <v-dialog v-model="dialog" persistent style="z-index: 20000" width="350">
    <v-card color="white" light>
      <v-card-text class="text-center py-5">
        <div class="primary--text text-center text-loading py-1 mb-3">
          {{ $t('processing_data') }}
        </div>
        <!-- <v-progress-linear indeterminate color="primary" class="mb-0 mt-2" /> -->
        <pulse-loader :loading="true" :size="size" :color="color" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PulseLoader from '@/components/dialog/PulseLoader.vue'
export default {
  components: { PulseLoader },
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      size: '18px',
      color: '#0f90e8'
    }
  },
}
</script>

<style lang="scss">
.v-overlay--active {
  .v-overlay__scrim {
    opacity: 0.2 !important;
  }
}
.text-loading {
  font-size: 18px;
}
</style>
